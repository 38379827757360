import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'bitmask'
})
export class BitMaskPipe implements PipeTransform {

  transform(propertiesVerified: number, numberCheck: number): number {
   return propertiesVerified&numberCheck;
  }

}
