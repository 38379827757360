<nav aria-label="Page navigation example">
  <ul class="pagination">
    <ng-template [ngIf]="firstPage !== 0">
      <li class="page-item" [ngClass]="{ disabled: currentPage === firstPage }">
        <button class="btn page-link border-0 rounded-0" (click)="switchPage(currentPage - 1)"><i data-feather='chevron-left' appFeatherIcon></i><small class='ms-1'>Précédent</small></button>
      </li>
    </ng-template>
    <ng-template [ngIf]="firstPage === 0">
      <li class="page-item" [ngClass]="{ disabled: currentPage === firstPage }">
        <button class="btn page-link border-0 rounded-0" (click)="switchPage(currentPage)"><i data-feather='chevron-left' appFeatherIcon></i><small class='ms-1'>Précédent</small></button>
      </li>
    </ng-template>
    <ng-template [ngIf]="section() > 1">
      <li class="page-item">
        <button class="btn page-link rounded-0" (click)="switchPage(1)">1</button>
      </li>
      <li class="page-item">
        <button class="btn page-link rounded-0" (click)="goBackAsSection()">...</button>
      </li>
    </ng-template>

    <ng-template [ngIf]="firstPage !== 0">
      <li class="page-item in" *ngFor="let page of pages()" [ngClass]="{ active: page === currentPage}">
        <button class="btn page-link rounded-0" (click)="switchPage(page)">{{ page }}</button>
      </li>
    </ng-template>
    <ng-template [ngIf]="firstPage === 0">
      <li class="page-item in" *ngFor="let page of pages()" [ngClass]="{ active: page === currentPage + 1}">
        <button class="btn page-link rounded-0" (click)="switchPage(page)">{{ page }}</button>
      </li>
    </ng-template>

    <ng-template [ngIf]="section() < sections()">
      <li class="page-item">
        <button class="btn page-link rounded-0" (click)="goForwardAsSection()">...</button>
      </li>
      <li class="page-item">
        <button class="btn page-link rounded-0" (click)="switchPage(lastPage)">{{ lastPage }}</button>
      </li>
    </ng-template>

    <ng-template [ngIf]="firstPage !== 0">
      <li class="page-item" [ngClass]="{ disabled: currentPage === lastPage }">
        <button class="btn page-link border-0 rounded-0" (click)="switchPage(currentPage + 1)"><small class='me-1'>Suivant</small> <i data-feather='chevron-right' appFeatherIcon></i></button>
      </li>
    </ng-template>
    <ng-template [ngIf]="firstPage === 0">
      <li class="page-item" [ngClass]="{ disabled: lastPage - currentPage === 1 }">
        <button class="btn page-link border-0 rounded-0" (click)="switchPage(currentPage + 2)"><small class='me-1'>Suivant</small> <i data-feather='chevron-right' appFeatherIcon></i></button>
      </li>
    </ng-template>
  </ul>
</nav>
