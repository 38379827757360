<ng-container *ngIf="error$ | async; else loading">
  <div #projectedError>
    <ng-content select="[error]"></ng-content>
  </div>

  <section [class.error-container__hidden]="projectedError.hasChildNodes()" class="error-container">
    <ng-container *ngIf="!useIconOnly">
      <p class="text-lg font-semibold capitalize">
        {{ errorMessage }}
      </p>
      <button (click)="retryLoad()" class="btn btn-primary btn-sm">
        {{ retryMessage }}
      </button>
    </ng-container>

    <ng-container *ngIf="useIconOnly">
      <a (click)="retryLoad()">
        <i class="fas fa-redo"></i>
      </a>
    </ng-container>
  </section>
</ng-container>

<ng-template #loading>
  <section #projectedLoader>
    <ng-content select="[loader]"></ng-content>
  </section>

  <section class="loader-container" [hidden]="projectedLoader.hasChildNodes()">
    <i class="fa fa-spinner fa-spin"></i>
  </section>
</ng-template>