import {Pipe, PipeTransform} from '@angular/core';
import * as dayjs from 'dayjs';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/fr-ca'

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  transform(date:any, type: string = 'fromNow'): any {
    dayjs.extend(relativeTime);
    dayjs.locale('fr-ca') // use locale
    switch (type) {
        case 'fromNow':
            return  dayjs(date).fromNow()
        case 'to':
            let now = dayjs(Date.now())
            return  dayjs(now).to(date)
        default:
            return  dayjs(date).fromNow()
    }
  }

}
