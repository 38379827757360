<div *ngIf="chartData?.data$ | async as Data; else loadingOrError">
  <apx-chart *ngIf="Data.series?.length" [series]="$any(Data.series)" [chart]="$any(Data.chart)"
    [colors]="$any(Data.colors)" [labels]="$any(Data.labels)" [legend]="$any(Data.legend)" [fill]="$any(Data.fill)"
    [stroke]="$any(Data.stroke)" [responsive]="$any(Data.responsive)" [xaxis]="$any(Data.xaxis)"
    [yaxis]="$any(Data.yaxis)" [plotOptions]="$any(Data.plotOptions)" [dataLabels]="$any(Data.dataLabels)"
    [theme]="$any(Data.theme)">
  </apx-chart>

  <h5 *ngIf="!Data.series?.length" class="p-3">
    Aucune donnée disponible.
  </h5>
</div>

<ng-template #loadingOrError>
  <loading-or-error *ngIf="chartData?.errorLoading$" [error$]="chartData!.errorLoading$" (retry)="emitRetry()">
  </loading-or-error>
</ng-template>