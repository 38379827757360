import {AfterViewInit, Directive} from '@angular/core';
// @ts-ignore
import feather from "feather-icons";

@Directive({
  selector: '[appFeatherIcon]'
})
export class FeatherIconDirective implements AfterViewInit {

  constructor() { }

  ngAfterViewInit() {
    // feather icon
    feather.replace();
  }

}
