<form class="export-form">
  <div class="export-form__input-section">
    <select (change)="emitPeriod($event.target.value)" class="form-control form-control-sm m-0">
      <option [value]="null" disabled selected>
        Sélectionnez une période
      </option>
      <option value="" selected>Depuis toujours</option>
      <option [value]="TimeRangeName.TODAY">Aujourd'hui</option>
      <option [value]="TimeRangeName.YESTERDAY">Hier</option>
      <option [value]="TimeRangeName.WEEK">7 derniers jours</option>
      <option [value]="TimeRangeName.MOUNTH">30 derniers jours</option>
    </select>
  </div>
</form>
