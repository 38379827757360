import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'colorForMethod'
})
export class GetColorForMethodPipe implements PipeTransform {

  transform(value: string): string {
    switch (value.toUpperCase()) {
      case 'GET':
      case 'SUCCESS':
        return 'badge-success'
      case "POST":
      case "PUT":
      case "PATCH":
        return 'badge-warning'
      case "DELETE":
      case "FAILED":
      case 'VALIDATION_FAILED':
        return 'badge-danger'
      default:
        return 'badge-light'
    }
  }

}
