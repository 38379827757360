<button
    [type]="type"
    class="btn btn-{{ color }}"
    [ngClass]="{'btn-block': block}"
    [disabled]="disabled"
>
    <i *ngIf="!!icon" class="mr-2"></i>
    <ng-content></ng-content>
    <span
        *ngIf="loading"
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
    ></span>
</button>
