import { PeriodDateFilter } from '@core/models/models';
import dayjs from '@shared/libs/dayjs.lib';

export const generateTimeRange = (
  timeOption: keyof typeof PeriodDateFilter
) => {
  let result: string = '';
  switch (timeOption) {
    case PeriodDateFilter.TODAY:
      result = `range[${dayjs()
        .startOf('day')
        .toISOString()},${dayjs().toISOString()}]`;
      break;
    case PeriodDateFilter.YESTERDAY:
      result = `range[${dayjs()
        .subtract(1, 'day')
        .startOf('day')
        .toISOString()},${dayjs()
        .subtract(1, 'day')
        .endOf('day')
        .toISOString()}]`;
      break;
    case PeriodDateFilter.WEEK:
      result = `range[${dayjs()
        .subtract(7, 'day')
        .startOf('day')
        .toISOString()},${dayjs().toISOString()}]`;
      break;
    case PeriodDateFilter.MOUNTH:
      result = `range[${dayjs()
        .subtract(1, 'month')
        .startOf('day')
        .toISOString()},${dayjs().toISOString()}]`;
      break;

    default:
      result = `range[${dayjs()
        .startOf('day')
        .toISOString()},${dayjs().toISOString()}]`;
      break;
  }

  return result;
};

export const generateTimeRangeObject = (
  timeOption: keyof typeof PeriodDateFilter
) => {
  let result: string = '';
  switch (timeOption) {
    case PeriodDateFilter.TODAY:
      return {
        startDateTime: dayjs().startOf('day').format(),
        endDateTime: dayjs().format(),
      };
    case PeriodDateFilter.YESTERDAY:
      return {
        startDateTime: dayjs().subtract(1, 'day').startOf('day').format(),
        endDateTime: dayjs().subtract(1, 'day').endOf('day').format(),
      };
    case PeriodDateFilter.WEEK:
      return {
        startDateTime: dayjs().subtract(7, 'day').startOf('day').format(),
        endDateTime: dayjs().format(),
      };
    case PeriodDateFilter.MOUNTH:
      return {
        startDateTime: dayjs()
          .subtract(1, 'month')
          .startOf('day')
          .format(),
        endDateTime: dayjs().format(),
      };

    default:
      return {
        startDateTime: dayjs().startOf('day'),
        endDateTime: dayjs().format(),
      };
  }

  return result;
};

export const getDateFromObject = (dateObject: dayjs.ConfigType) => {
  if (!!dateObject && (dateObject as any)['month']) {
    return dayjs({
      ...(dateObject as any),
      month: (dateObject as any)['month'] - 1,
    }).format();
  }
  return dayjs(dateObject).format();
};

export const getDateValueFromObject = (dateObject: dayjs.ConfigType) => {
  if (!!dateObject && (dateObject as any)['month']) {
    return dayjs({
      ...(dateObject as any),
      month: (dateObject as any)['month'] - 1,
    }).valueOf();
  }
  return dayjs(dateObject).valueOf();
};

export const generateTimeRangeParams = (
  period?: PeriodDateFilter,
  generateObject = false
) => {
  if (
    !period ||
    period === PeriodDateFilter.DATE ||
    period === PeriodDateFilter.RANGE
  ) {
    return null;
  }

  return generateObject
    ? generateTimeRangeObject(period)
    : generateTimeRange(period);
};
