import { Role } from "@views/pages/profile/models/user.model";
import { IMatrixRole } from "./matrix.model";

export const matrixRoleData: IMatrixRole[] = [
    {
        module: 'SECURITE',
        groups: [
            {
                features: [
                    'Authentification',
                    'Réinitialisation mot de passe'
                ],
                roles: [
                    Role.ROLE_USER,
                    Role.ROLE_ORGANIZATION_ADMIN,
                    Role.ROLE_ADMIN,
                    Role.ROLE_SUPER_ADMIN
                ]
            }
        ]
    },
    {
        module: 'UTILISATEUR',
        groups: [
            {
                features: [
                    'Créer',
                    'Lire',
                    'Lister',
                    'Modifier',
                    'Supprimer',
                    'Promouvoir',
                    'Rétrograder',
                    'Activer le compte',
                    'Désactiver le compte',
                ],
                roles: [
                    Role.ROLE_ADMIN,
                    Role.ROLE_SUPER_ADMIN
                ]
            }
        ]
    },
    {
        module: 'ORGANISATION',
        groups: [
            {
                features: [
                    'Création',
                    'Liste',
                    'Modification',
                    'Détails',
                    'Mise à jour'
                ],
                roles: [
                    Role.ROLE_ADMIN,
                    Role.ROLE_SUPER_ADMIN,
                ]
            },
            {
                features: [
                    'Ajout utilisateur à organisation',
                    'Création utilisateur pour organisation',
                    'Génération de jeton d\'accès organisation',
                    'Suppression  jeton d\'accès',
                    'Générer les transmission d\'une organisation',
                    'Création liste de diffusion pour',
                    'Modifier liste de diffusion pour organisation',
                    'Supprimer liste de diffusion d’une organisation',
                    'Promouvoir un utilisateur',
                    'Rétrograder un utilisateur'
                ],
                roles: [
                    Role.ROLE_ADMIN,
                    Role.ROLE_ORGANIZATION_ADMIN,
                    Role.ROLE_SUPER_ADMIN,
                ]
            },
        ]
    },
    {
        module: 'REFERENTIEL',
        groups: [
            {
                features: [
                    'Créer',
                    'Lire',
                    'Lister',
                    'Modifier',
                    'Ajouter nouvelle version',
                    'Télécharger la dernière version',
                    'Télécharger une version spécifique',
                    'Configurer automatiquement',
                    'Configurer manuellement',
                ],
                roles: [
                    Role.ROLE_ADMIN,
                    Role.ROLE_SUPER_ADMIN
                ]
            }
        ]
    },
    {
        module: 'RAPPORT DE TRANSMISSION',
        groups: [
            {
                features: [
                    'Lister tous les rapports de transmission',
                    'Filtrer les rapports de transmission par récepteur',
                    'Filtrer les rapports de transmission par expéditeur',
                    'Filtrer les rapports de transmission par date',
                ],
                roles: [
                    Role.ROLE_ADMIN,
                    Role.ROLE_SUPER_ADMIN
                ]
            }
        ]
    },
    {
        module: 'AUDIT TRAILS',
        groups: [
            {
                features: [
                    'Lister',
                    'Voir l\'utilisateur qui a fait l\'action',
                    'Filtrer par date et période',
                    'Filtrer par service',
                    'Filtrer par code d\'état de la réponse HTTP',
                    'Filtrer par méthode HTTP utilisée',
                    'Filtrer par organisation',
                    'Filtrer par utilisateur',
                    'Filtrer par rôle utilisateur',
                ],
                roles: [
                    Role.ROLE_ADMIN,
                    Role.ROLE_SUPER_ADMIN
                ]
            }
        ]
    },
]