import {Pipe, PipeTransform} from '@angular/core';
import {TableListEnum} from '@shared/utils/mock/table.enum';


@Pipe({
  name: 'filterValue'
})
export class FilterValuePipe implements PipeTransform {

  transform(value: string): string {
    let filterValue!: string;
    for (const obj in TableListEnum) {
      if (value === obj) {
        // @ts-ignore
        filterValue = TableListEnum[obj];
        break;
      }
    }
    return filterValue;
  }

}
