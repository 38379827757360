import {Pipe, PipeTransform} from '@angular/core';
import {Role} from '@views/pages/profile/models/user.model';
import {userRoles} from '../mock/user-role.mock';

@Pipe({
  name: 'getRoleName'
})
export class GetRoleName implements PipeTransform {

    roles: any[] = userRoles;

    transform(role: Role): boolean {
        const hasRole = this.roles.find(elt => elt.key === role)
        return hasRole ? hasRole.value : role
    }

}
