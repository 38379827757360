import { Pipe, PipeTransform } from '@angular/core';
/**
 * Apply a function to an input
 * from component template.
 * Provided function needs to be pure
 * for optimized performances
 */
@Pipe({
  name: 'useFunction',
  pure: true,
})
export class UseFunctionPipe implements PipeTransform {
  transform(input: any, formatFunction: Function | undefined) {
    if (!formatFunction) {
      return input;
    }
    return formatFunction(input);
  }
}
