import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'loading-or-error',
  templateUrl: './loading-or-error.component.html',
  styleUrls: ['./loading-or-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingOrErrorComponent {
  /**
   * Observable to subscribe to
   */
  @Input() error$: Observable<any> | undefined;

  /**
   * Text message to display when error occurs
   */
  @Input() errorMessage = `Une erreur s'est produite.`;

  /**
   * Retry Button text
   */
  @Input() retryMessage = 'réessayer';

  /**
   * Use icon only when error occurs
   */
  @Input() useIconOnly = true;

  @Input() color: 'primary' | 'accent' | 'warn' | undefined;
  @Output() retry = new EventEmitter();
  constructor() {}

  retryLoad() {
    this.retry.emit();
  }
}
