
<div class='modal-header border-bottom-0'>
    <h5 class='fs-5 mx-md-3'>Matrice des droits utilisateurs</h5>
</div>
  <div class='modal-body p-0'>
    <table class="table">
        <thead class="bg-light">
            <tr>
              <th scope="col-4">Fonctionnalités</th>
              <th scope="col" *ngFor="let role of roles" >{{role | roleLabel}}</th>
            </tr>
          </thead>
          <tbody>
              <ng-container  *ngFor="let matrix of matrixData">
                <tr class="bg-light">
                    <th colspan="5">{{matrix.module}}</th>
                </tr>
                <ng-container *ngFor="let group of matrix.groups" >
                    <tr *ngFor="let feature of group.features">
                        <td>{{feature}}</td>
                        <td *ngFor="let role of roles">
                            <span class="text-success" *ngIf="group.roles.includes(role) else notIn" >
                                <i class="fa fa-check fa-1x"></i>
                            </span>
                            <ng-template #notIn>
                                <span class="text-danger">
                                    <i class="fas fa-times fa-1x"></i>
                                </span>
                            </ng-template>
                        </td>
                    </tr>
                </ng-container>
            </ng-container>
          </tbody>
    </table>
  </div>
  <div class='modal-footer border-top-0'>
    <button (click)="activeModal.close('Close click')" type='button' class='btn btn-default'>Fermer</button>
  </div>
  