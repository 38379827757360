import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { PeriodDateFilter } from '@core/models/models';
import { NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { generateTimeRange, getDateFromObject } from '@shared/utils/time';
import { finalize, Observable, Subject, takeUntil, tap } from 'rxjs';
import dayjs from '@shared/libs/dayjs.lib';
import { tsXLXS } from 'ts-xlsx-export';

@Component({
  selector: 'export-button',
  templateUrl: './export-button.component.html',
  styleUrls: ['./export-button.component.scss'],
})
export class ExportButtonComponent implements OnInit {
  @ViewChild('period', { static: false }) modalContainer!: TemplateRef<any>;
  @Input() requestConstructor!: (timeRange: string) => Observable<any> | undefined;
  @Input() fileName: string | undefined;

  selectedPeriod = new FormControl(null, Validators.required);
  PeriodDateFilter = PeriodDateFilter;

  currentTimeRange: string | undefined;

  isLoading = false;
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate| null = null;
  toDate: NgbDate | null = null;

  private _destroy$ = new Subject<void>();
  constructor(private modalService: NgbModal) {}

  ngOnInit() {
    this.listenToPeriodChanges();
  }

  openModal() {
    this.modalService.open(this.modalContainer);
  }

  listenToPeriodChanges() {
    this.selectedPeriod.valueChanges
      .pipe(
        takeUntil(this._destroy$),
        tap({
          next: (period) => {
            this.currentTimeRange = undefined;

            if (period === '') {
              this.currentTimeRange = '';
              return;
            }

            if (
              [PeriodDateFilter.DATE, PeriodDateFilter.RANGE].includes(period)
            ) {
              return;
            }

            this.currentTimeRange = generateTimeRange(period);
          },
        })
      )
      .subscribe();
  }

  onDateSelect(date: NgbDate) {
    this.currentTimeRange = getDateFromObject(date);
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    if (!!this.fromDate && !!this.toDate) {
      const start = getDateFromObject(this.fromDate);
      const end = getDateFromObject(this.toDate);
      this.currentTimeRange = `range[${start},${end}]`;
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  export () {
    const fetchRequest$ = this.requestConstructor(this.currentTimeRange!);
    if (!fetchRequest$) {
      return;
    }
    this.isLoading = true;
    fetchRequest$.pipe(
      takeUntil(this._destroy$),
      finalize(() => this.isLoading = false),
      tap({
        next: res => {
          const fileName = `${this.fileName || 'export'} - ${dayjs().format('DD-MM-YYYY HH:mm')}`;
          tsXLXS().exportAsExcelFile(!!res.items ? res.items : res).saveAsExcelFile(fileName);
          this.modalService.dismissAll();
        }
      })
    ).subscribe();
  }
}
