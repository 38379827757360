import {Pipe, PipeTransform} from '@angular/core';
import {Role} from '@views/pages/profile/models/user.model';

@Pipe({
  name: 'hasRole'
})
export class HasRolePipe implements PipeTransform {

  transform(roles: Role[], has: Role): boolean {
   return roles.includes(has);
  }

}
