import {Observable} from "rxjs";

export interface Filter {
    type: TypeFilter,
    value?: ValueFilter;
    text?: string;
    property: string;
    items: string[];
    isOpen: boolean;
    isSearch: boolean;
    isSearchByText?: boolean;
    observable$?: (params: any) => Observable<any>
}

export interface ValueFilter {
    id: string;
    text: string;
}

export enum TypeFilter {
    PROP = 'prop',
    FILTER = 'filter'
}

export enum FilterAction {
    CLOSE = 'close',
    OPEN = 'open'
}
