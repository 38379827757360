import {Pipe, PipeTransform} from '@angular/core';
import {organizationType} from "@shared/utils/mock/type.mock";

@Pipe({
  name: 'organizationType'
})
export class OrganizationTypePipe implements PipeTransform {

  transform(value: string, ...args: string[]): string {
    let typeValue!: string;
    for (const elem of organizationType) {
      if(value === elem?.key) {
        typeValue = elem?.value;
        break;
      }
    }
    return typeValue;
  }

}
