export enum TableListEnum {
  lastName = 'nom',
  firstName = 'prenom',
  name = 'nom',
  email = 'email',
  phoneNumber = 'numéro de téléphone',
  enabled = 'état',
  locked = 'statut',
  description = 'description',
  type = 'type'
}
