<div class='modal-header'>
  <h5 class='fs-5 mx-md-3'>{{headerText}}</h5>
  <div (click)="activeModal.dismiss('Cross click')" class='cursor-pointer'>
    <i data-feather='x' appFeatherIcon></i>
  </div>
</div>
<div class="modal-body">
  <div class="alert alert-danger" role="alert">
    <span><i class="fa fa-exclamation-triangle"></i></span>
    <span class="ms-2">
      Cette action est irréversible, êtes vous sûr de vouloir continuer ?
    </span>
  </div>
  <p>Veuillez saisir ce mot pour confirmer : <span class="font-weight-bolder ms-2">{{text}}</span></p>
  <div class='form-row mt-2'>
    <div class='form-group col mb-4'>
      <input type='text' placeholder='Entrer le mot' [formControl]="inputCtr" class='form-control' #input
            [class.is-invalid]="!isMatched">
      <small *ngIf="!isMatched" class="text-danger" >{{messageError ?? 'Le texte saisi est incorrect'}}</small>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button (click)="activeModal.close('Close click')" type='button' class='btn btn-default '>Fermer</button>
  <app-button
      [disabled]="inputCtr.invalid"
      [block]="true" 
      [loading]="isLoading"
      [type]="'button'"
      [color]="'danger'"
      (click)='onDelete()' class=' ms-3'>Supprimer</app-button>
</div>
  