import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges
} from '@angular/core';
import { APEX_CHART_LOCALES } from '@core/constants/constants';
import { ChartOptions } from '@core/models/models';
import { LoadListener } from '@shared/utils/rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartComponent {
  @Output() retry = new EventEmitter<any>();
  @Input() chartData: LoadListener<ChartOptions> | undefined;
  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['chartData']?.currentValue) {
      this.chartData = new LoadListener(
        this.chartData!.data$.pipe(
          map(options => {
            return {
              theme: {
                mode: 'light',
                palette: 'palette7'
              },
              ...options,
              chart: {
                ...options.chart,
                locales: APEX_CHART_LOCALES,
                defaultLocale: 'fr'
              }
            } as ChartOptions;
          })
        )
      );
    }
  }

  emitRetry() {
    this.retry.emit(true);
  }
}
