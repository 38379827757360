<button (click)="openModal()" type="button" class="btn custom">
    <i class="fa file-export"></i>
    Exporter
</button>
<!-- <div class="d-inline-block" ngbDropdown #drop="ngbDropdown">
    
    <div ngbDropdownMenu aria-labelledby="dropdownManual" >
        <button ngbDropdownItem (click)="selectDate(PeriodeDateFilter.TODAY)">Aujourd'hui</button>
        <button ngbDropdownItem (click)="selectDate(PeriodeDateFilter.YESTERDAY)">Hier</button>
        <button ngbDropdownItem (click)="selectDate(PeriodeDateFilter.WEEK)">7 derniers jours</button>
        <button ngbDropdownItem (click)="selectDate(PeriodeDateFilter.MOUNTH)">30 derniers jours</button>
        <button ngbDropdownItem (click)="open(date, PeriodeDateFilter.DATE)">Date</button>
        <button ngbDropdownItem (click)="open(periode, PeriodeDateFilter.RANGE)">Périodique</button>
    </div>
</div> -->

<ng-template #period let-modal>
    <header class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Exporter des données</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </header>

    <section class="modal-body">
        <label for="period" class="mb-2">Sélectionnez une période <span class='text-danger'>*</span></label>
        <select id="period" [formControl]="selectedPeriod" class="form-control mb-0">
            <option [value]="null" disabled selected>Veuillez choisir une option</option>
            <option value="">Depuis toujours</option>
            <option [value]="PeriodDateFilter.TODAY">Aujourd'hui</option>
            <option [value]="PeriodDateFilter.YESTERDAY">Hier</option>
            <option [value]="PeriodDateFilter.WEEK">7 derniers jours</option>
            <option [value]="PeriodDateFilter.MOUNTH">30 derniers jours</option>
            <option [value]="PeriodDateFilter.DATE">Date</option>
            <option [value]="PeriodDateFilter.RANGE">Périodique</option>
        </select>

        <div class="col-12 mt-4 d-flex">
            <ngb-datepicker *ngIf="selectedPeriod.value === PeriodDateFilter.DATE" (dateSelect)="onDateSelect($event)" outsideDays="hidden">
            </ngb-datepicker>

            <ngb-datepicker *ngIf="selectedPeriod.value === PeriodDateFilter.RANGE" #dp (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t" outsideDays="hidden">
            </ngb-datepicker>
            
            <ng-template #t let-date let-focused="focused">
                <span
                    class="custom-day"
                    [class.focused]="focused"
                    [class.range]="isRange(date)"
                    [class.faded]="isHovered(date) || isInside(date)"
                    (mouseenter)="hoveredDate = date"
                    (mouseleave)="hoveredDate = null"
                >
                    {{ date.day }}
                </span>
            </ng-template>
        </div>
    </section>

    <footer class="modal-footer">
        <app-button (click)="export()" [disabled]="isLoading || currentTimeRange === undefined" [loading]="isLoading"
            [type]="'button'" [color]="'primary'">
            Exporter
        </app-button>
    </footer>
</ng-template>