import {Component, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {finalize, first, Observable} from 'rxjs';

@Component({
  selector: 'delete-modal',
  templateUrl: './delete-modal.component.html',
  styles: [
  ]
})
export class DeleteModalComponent implements OnInit {

  text: string = '';

  headerText: string = 'Supprimer'

  inputCtr = new FormControl(null, [Validators.required]);

  isLoading: boolean = false;

  isMatched: boolean = true;

  message: string = ''

  messageError?: string

  observable$?: Observable<any>

  constructor(
    public activeModal: NgbActiveModal,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
  }

  onDelete() {
    if (
      this.inputCtr.value == this.text
      && this.observable$) {
      this.isLoading = true;
      this.isMatched = true;
      this.observable$
      .pipe(first(), finalize(() => this.isLoading = false))
      .subscribe({
        complete: () => {
          this.activeModal.close('close modal');
          this.toastr.success(this.message);
        }
      })
    }  else {
      this.isMatched = false;
      this.isLoading = false;
    }
  }

}
