<div class="d-inline-block" ngbDropdown #drop="ngbDropdown">
    <button type="button" class="btn custom" [ngClass]="class" id="dropdownManual" ngbDropdownAnchor (focus)="drop.open()">
      <i class="fa fa-calendar"></i>
      Date
    </button>
    <div ngbDropdownMenu aria-labelledby="dropdownManual" >
        <button ngbDropdownItem (click)="selectDate(PeriodeDateFilter.TODAY)">Aujourd'hui</button>
        <button ngbDropdownItem (click)="selectDate(PeriodeDateFilter.YESTERDAY)">Hier</button>
        <button ngbDropdownItem (click)="selectDate(PeriodeDateFilter.WEEK)">7 derniers jours</button>
        <button ngbDropdownItem (click)="selectDate(PeriodeDateFilter.MOUNTH)">30 derniers jours</button>
        <button ngbDropdownItem (click)="open(date, PeriodeDateFilter.DATE)">Date</button>
        <button ngbDropdownItem (click)="open(periode, PeriodeDateFilter.RANGE)">Périodique</button>
    </div>
</div>

<ng-template #periode let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Période</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <ngb-datepicker #dp (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t" outsideDays="hidden">
        </ngb-datepicker>
        <ng-template #t let-date let-focused="focused">
          <span class="custom-day"
                [class.focused]="focused"
                [class.range]="isRange(date)"
                [class.faded]="isHovered(date) || isInside(date)"
                (mouseenter)="hoveredDate = date"
                (mouseleave)="hoveredDate = null">
                {{ date.day }}
          </span>
        </ng-template>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="selectDate(PeriodeDateFilter.RANGE)">Selectionner</button>
    </div>
</ng-template>

<ng-template #date let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Période</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <ngb-datepicker #dp (dateSelect)="onDateSelection($event)" (navigate)="null"></ngb-datepicker>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="selectDate(PeriodeDateFilter.RANGE)">Selectionner</button>
    </div>
</ng-template>