<div class="card">
    <div class="card-body">
      <form [formGroup]="form">
            <div class="d-flex justify-content-between">
              <label>Filtrer par :</label>
              <button class="btn btn-link" (click)="onClearAll()">Tout retirer</button>
            </div>
            <div class="row border border-1 bg-light">
              <div class="form-row" >
                  <ng-container formArrayName="filters">
                    <ng-container *ngFor="let filter of filters.controls; let i = index">
                      <div class='form-group col-md-6 col-lg-3 my-2'  [formGroupName]="i">   
                        <ng-select 
                          [closeOnSelect]="false"
                          [searchable]="filter.value.isSearch"
                          [items]="filter.value.items"
                          [isOpen]="filter.value.isOpen"
                          bindLabel="text"
                          (open)="clearFilter(i)"
                          (close)="clearFilter(i)"
                          (clear)="clearFilter(i)"
                          (search)="onSearch($event.term, i)"
                          (change)="onChange(i)"
                          (focus)="onToggle(FilterAction.OPEN)"
                          formControlName="value"
                          notFoundText="Aucun element">
                            <ng-template ng-label-tmp let-item="item">
                              {{filter.value.isSearch ? '' : filter.value.text ? filter.value.text+':' : ''}}
                              <span class="badge bg-primary">{{item.text | getRoleName}}</span>
                          </ng-template>
                        </ng-select>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
          </div>
      </form>
    </div>
    <div class="card-footer">
      <div class="d-flex justify-content-end">
        <button class="btn btn-light mr-2" 
          (click)="close.emit(false)"
        >Fermer</button>
        <button class="btn btn-primary" 
          (click)="onSubmit()"
        >Valider</button>
      </div>
    </div>
  </div>