<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6 d-flex align-items-center">
        <h1 class="m-0 text-dark">{{data?.title}}</h1>
        <span class="ml-2" *ngIf="data?.infos as infos" placement="bottom" ngbTooltip={{infos}}><i class="fa fa-exclamation-circle"></i></span>
      </div>
      <!-- /.col -->
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a [routerLink]="[]" >{{data.page ?? data.title ?? 'Accueil'}}</a></li>
          <li class="breadcrumb-item active">{{data?.subtitle}}</li>
        </ol>
      </div>
      <!-- /.col -->
    </div>
    <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</div>
