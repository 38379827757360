export const userRoles = [
  {key: 'ROLE_SUPER_ADMIN', value: 'Super Administrateur'},
  {key: 'ROLE_ADMIN', value: 'Administrateur de la plateforme'},
  // {key: 'ROLE_ORGANIZATION_ADMIN', value: 'Admin Organisation'},
];
// ROLE_USER = "ROLE_USER",
// ROLE_ADMIN = "ROLE_ADMIN",
// ROLE_SUPER_ADMIN = "ROLE_SUPER_ADMIN",
// ROLE_ORGANIZATION = "ROLE_ORGANIZATION",
// ROLE_ORGANIZATION_ADMIN = "ROLE_ORGANIZATION_ADMIN",
// ROLE_ORGANIZATION_DEFAULT = "ROLE_ORGANIZATION_DEFAULT",
// ROLE_ORGANIZATION_BENEFIT = "ROLE_ORGANIZATION_BENEFIT",
// ROLE_ORGANIZATION_INSURANCE = "ROLE_ORGANIZATION_INSURANCE",
// ROLE_ORGANIZATION_CONTRIBUTION = "ROLE_ORGANIZATION_CONTRIBUTION",
// ROLE_ORGANIZATION_HEALTH_CENTER = "ROLE_ORGANIZATION_HEALTH_CENTER",

export const roleOrganizationAdmin = [
  {key: 'ROLE_ORGANIZATION_ADMIN', value: 'Administrateur de l\'organisation'},
]
