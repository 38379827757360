import { RequestParams } from "@core/models/models";

export type CareSheet = {
  id: string;
  reference: string;
  parent?: CareSheet,
  patient: {
    socialSecurityNumber: string;
    name: string;
    gender: 'M' | 'F';
    birthdate: string;
    insurancePolicies: [
      {
        type: 'COMPLEMENTARY' | 'BASIC';
        insurer: {
          code: string;
        name: string;
        };
        coverageRate: number;
      }
    ];
  };
  complementaryInsurer?: {
    code: string;
    name: string;
  };
  careProfessional?: {
    code: string;
    name: string;
  };
  affectionCodes: ['PALU', 'GRIP'];
  items: CareItem[];
  createdAt: string;
  updatedAt: string;
};

export type Transaction = {
  id: string;
  careSheet: CareSheet;
  careItems: CareItem[];
  insurancePolicyType: TransactionPolicyType;
  status: TransactionStatus;
  statusReason: string;
  createdAt: string;
};

export type CareItem = {
  type: CareItemType;
  code: string;
  name: string;
  prescribedQuantity: number;
  servedQuantity: number;
  unitPrice: {
    amount: number;
    currency: string;
  };
  partToBePaidByBasicInsurance: {
    amount: number;
    currency: string;
  };
  partToBePaidByComplementaryInsurance: {
    amount: number;
    currency: string;
  };
  totalAmountSupported: {
    amount: number;
    currency: string;
  };
};

export enum CareItemType {
  DRUG = 'DRUG',
  ACT = 'ACT',
}

export enum TransactionPolicyType {
  SYSTEM = 'SYSTEM',
  BASIC = 'BASIC',
  COMPLEMENTARY = 'COMPLEMENTARY',
}

export enum TransactionStatus {
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  TIMEOUT = 'TIMEOUT'
}

export type TransactionMetricsParams = {
  startDateTime?: string;
  endDateTime?: string;
  criteria?: {
    status?: TransactionStatus | TransactionStatus[] | string;
    insurancePolicyType?: string;
    organizationId?: string;
  };
  groupBy?: Array<
    | 'status'
    | 'insurancePolicyType'
    | 'organizationId'
  >;
  sort?: Array<
    | 'total'
    | 'status'
    | 'insurancePolicyType'
    | 'organizationId'
  >;
  desc?: Array<
    | 'total'
    | 'status'
    | 'insurancePolicyType'
    | 'organizationId'
  >;
} & RequestParams;

export type TransactionStatistic = {
  total: number;
};

export type TransactionMetric = TransactionStatistic & {
  period: {
    year: number;
    month: number;
    day: number;
  };
};
