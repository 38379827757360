import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { PeriodDateFilter } from '@core/models/models';

@Component({
  selector: 'export-form',
  templateUrl: './export-form.component.html',
  styleUrls: ['./export-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExportFormComponent {
  @Output() periodChange = new EventEmitter<PeriodDateFilter>();

  @Input() selectMultipleProcessorInfo = false;

  TimeRangeName = PeriodDateFilter;

  constructor() {}

  emitPeriod(period: PeriodDateFilter) {
    this.periodChange.emit(period);
  }
}
