import { IMatrixRole } from './matrix.model';
import { Component, Input, OnInit } from '@angular/core';
import { matrixRoleData } from './matrix.mock';
import { Role } from '@views/pages/profile/models/user.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-roles-matrix',
  templateUrl: './roles-matrix.component.html',
  styles: [
    `
    `]
})
export class RolesMatrixComponent implements OnInit {

  @Input()
  matrixData: IMatrixRole[] = matrixRoleData;

  @Input()
  roles: Role[] = [
    Role.ROLE_USER,
    Role.ROLE_ORGANIZATION_ADMIN,
    Role.ROLE_ADMIN,
    Role.ROLE_SUPER_ADMIN,
  ]

  constructor(
    public activeModal: NgbActiveModal
  ) { 
    //todo sort roles group by header roles
  }

  ngOnInit(): void {
  }

}
