import { Component, Input } from '@angular/core';
import { TransactionStatus } from '@views/pages/adjudications/models/adjudication.model';

@Component({
  selector: 'transaction-status-badge',
  templateUrl: './transaction-status-badge.component.html',
  styleUrls: ['./transaction-status-badge.component.scss']
})
export class TransactionStatusBadgeComponent {
  @Input() status: TransactionStatus | undefined | null;
  constructor() {}

  get colorClass() {
    if (this.status === TransactionStatus.COMPLETED) {
      return 'transaction-status-badge transaction-status-badge--completed';
    }

    if ([TransactionStatus.FAILED, TransactionStatus.TIMEOUT].includes(this.status!)) {
      return 'transaction-status-badge transaction-status-badge--failed';
    }

    if (this.status === TransactionStatus.PROCESSING) {
      return 'transaction-status-badge transaction-status-badge--pending';
    }


    return 'transaction-status-badge transaction-status-badge--default';
  }

  get name() {
    if (this.status === TransactionStatus.COMPLETED) {
      return 'Terminé';
    }
    if (this.status === TransactionStatus.FAILED) {
      return 'Echouée';
    }

    if (this.status === TransactionStatus.PROCESSING) {
      return 'En Attente';
    }

    if (this.status === TransactionStatus.TIMEOUT) {
      return 'Delai d\attente expiré';
    }

    return 'Inconnu';
  }

}
