import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { PeriodDateFilter } from '@core/models/models';
import {NgbCalendar, NgbDate, NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'date-filter',
  templateUrl: './date-filter.component.html',
  styles: [`
  .custom-day {
    text-align: center;
    padding: 0.185rem 0.25rem;
    display: inline-block;
    height: 2rem;
    width: 2rem;
  }
  .custom-day.focused {
    background-color: #e6e6e6;
  }
  .custom-day.range, .custom-day:hover {
    background-color: rgb(2, 117, 216);
    color: white;
  }
  .custom-day.faded {
    background-color: rgba(2, 117, 216, 0.5);
  }
`]
})
export class DateFilterComponent implements OnInit {

  @Input()
  dateProperty: string = 'createdAt';

  @Input()
  class: string = 'btn-primary';

  @Output()
  params: EventEmitter<Object> = new EventEmitter();


  private range: string = '';

  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate;
  toDate: NgbDate | null = null;

  PeriodeDateFilter: typeof PeriodDateFilter = PeriodDateFilter;

  constructor(
    calendar: NgbCalendar,
    private modalService: NgbModal
    ) {
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
  }

  ngOnInit(): void {
  }

  selectDate(periode: PeriodDateFilter) {
    let now = new Date();
    let leftDate: Date = this.setDate(now.getDate(), 0, 0, 0, 0)
    switch (periode) {
      case PeriodDateFilter.TODAY:
        this.range = `range[${leftDate.toISOString()},${new Date().toISOString()}]`
        this.setParam()
        break;
      case PeriodDateFilter.YESTERDAY:
        leftDate.setDate(now.getDate() - 1);
        this.range = `range[${leftDate.toISOString()}, ${this.setDate(leftDate.getDate(), 23, 59, 0, 0).toISOString()}]`
        this.setParam()
        break;
      case PeriodDateFilter.WEEK:
        leftDate.setDate(now.getDate() - 7);
        this.range = `range[${leftDate.toISOString()}, ${this.setDate(leftDate.getDate(), 23, 59, 0, 0).toISOString()}]`
        this.setParam()
        break;
      case PeriodDateFilter.MOUNTH:
        leftDate.setDate(now.getDate() - 30);
        this.range = `range[${leftDate.toISOString()},${this.setDate(leftDate.getDate(), 23, 59, 0, 0).toISOString()}]`
        this.setParam()
        break;
      case PeriodDateFilter.RANGE:
        const fromDate = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day);
        const toDate = new Date(this.toDate!.year, this.toDate!.month - 1, this.toDate?.day);
        this.range = `range[${fromDate.toISOString()},${toDate.toISOString()}]`
        this.modalService.dismissAll()
        this.setParam()
        break;
      default:
        break;
    }
  }

  setParam() {
    this.params.emit({
      [this.dateProperty]: this.range
    })
  }

  open(content: any, choose: PeriodDateFilter) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    }, (reason) => {
    });
  }


  private setDate(
    d: number,
    h: number,
    m: number,
    s: number,
    ms: number
  ) {
    let now = new Date();
    now.setDate(d)
    now.setHours(h)
    now.setMinutes(m)
    now.setSeconds(s)
    now.setMilliseconds(ms)
    return now
  }


  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

}


