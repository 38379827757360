import {Injectable} from '@angular/core';
import {catchError, map, Observable, throwError} from 'rxjs';
import {IdentityService} from "@core/services/api/identity/identity.service";
import {environment} from '@environments/environment';
import {IUser} from '@views/pages/profile/models/user.model';
import {Pagination} from '@shared/Models/pagination.model';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class UserService {

  private endpoint = {
    users: 'users'
  };

  constructor(
    private identityService: IdentityService
  ) {
  }

  public getAllUsers(params?: any): Observable<Pagination<IUser>> {
    return this.identityService.get(`${this.endpoint.users}`, params).pipe(
      map((res: any) => res),
      catchError(error => throwError(() => error))
    );
  }

  public getUser(userId: any, params?: any) : Observable<any> {
    return this.identityService.get(`${this.endpoint.users}/${userId}`, params).pipe(
      map((res: any) => res),
      catchError(error => throwError(() => error))
    )
  }

  public createUser(data: any): Observable<any> {
    return this.identityService.post(`${this.endpoint.users}`, data).pipe(
      map((res:any) => res),
      catchError(error => throwError(() => error))
    )
  }

  public updateUser(data: any, userId: any): Observable<any> {
    return this.identityService.patch(`${this.endpoint.users}/${userId}`, data).pipe(
      map((res:any) => res),
      catchError(error => throwError(() => error))
    )
  }

  public deleteUser(userId: any, params?:any): Observable<any> {
    return this.identityService.delete(`${this.endpoint.users}/${userId}`, params).pipe(
      map((res: any) => res),
      catchError(error => throwError(() => error))
    )
  }

  public changeStatus(data: any, userId: any) : Observable<any> {
    return this.identityService.patch(`${this.endpoint.users}/${userId}`, data);
  }

  public promoteUser(userId: any, data: any, endpoint: any) : Observable<any> {
    return this.identityService.put(`${this.endpoint.users}/${userId}/${endpoint}`, data).pipe(
      map((res: any) => res),
      catchError(error => throwError(() => error))
    )
  }

  public changePartialValue(data: any, userId: any) : Observable<any> {
    return this.identityService.patch(`${this.endpoint.users}/${userId}`, data).pipe(
      map((res: any) => res),
      catchError(error => throwError(() => error))
    )
  }

  public resetPassword(email: string) {
    return this.identityService.post(`${this.endpoint.users}/password/resetting/request`, {
      username: email,
      resetUrl: environment.webUrl+'/se-connecter/recovery-password'
    })
    .pipe(
      catchError(error => throwError(() => error))
    );
  }

  public resetPasswordWithToken(password: string, token: any) {
    return this.identityService
      .post(
        `${this.endpoint.users}/password/resetting/reset`,
        {
          password,
        },
        {
          headers: new HttpHeaders({
            Authorization: `Otp ${token}`,
          }),
          params: {
            skip: true,
          },
        }
      )
      .pipe(catchError((error) => throwError(() => error)));
  }

  public updatePassword(password: string, newPassword: string, userId: string) {
    return this.identityService.put(`${this.endpoint.users}/${userId}/change/password`, {
      password,
      newPassword
    },
    {
      params: {
        skip: true
      }
    })
    .pipe(
      catchError(error => throwError(() => error))
    );
  }

  verifyUser(value: string) {
    const data = {
      field: 'email',
      value: value,
      redirectUrl: environment.webUrl+'/se-connecter/verify'
    }
    return this.identityService.post(`${this.endpoint.users}/fields/check/request`, data)
  }
}
