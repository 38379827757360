import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'state'
})
export class StatePipe implements PipeTransform {

  transform(value: boolean): string {
   return value ? 'Actif' : 'Bloqué';
  }

}
