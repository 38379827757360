import {Pipe, PipeTransform} from '@angular/core';
import * as dayjs from 'dayjs';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/fr-ca'
dayjs.extend(relativeTime);
dayjs.locale('fr-ca') // use locale

@Pipe({
  name: 'updatedDateFormat'
})
export class UpdatedDateFormatPipe implements PipeTransform {

  transform(value:any): any {
    return value ? dayjs(value).fromNow(): '';
  }

}
