import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {NgbCalendar, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import {Filter, FilterAction, TypeFilter, ValueFilter} from '@shared/Models/filter.model';
import {UserService} from '@views/pages/users/services/user.service';
import {first} from 'rxjs';

@Component({
  selector: 'filters',
  templateUrl: './filters.component.html',
  styles: [`
    :host ::ng-deep .ng-select .ng-arrow-wrapper {
      display: none !important
    }
  `]
})
export class FiltersComponent implements OnInit {

  @Input()
  itemsFilter: Filter[] = [];

  @Output()
  getParams: EventEmitter<Object> = new EventEmitter()

  @Output()
  close: EventEmitter<false> = new EventEmitter()

  @Input()
  params: Object = {};

  form: FormGroup = new FormGroup({})

  FilterAction: typeof FilterAction = FilterAction;

  TypeFilter: typeof TypeFilter = TypeFilter;

  private readonly userPrint: string = 'Votre saisie --> ';

  constructor(
    private fb: FormBuilder,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    public userService: UserService
    ) {
  }

  ngOnInit(): void {

    this.form = this.fb.group({
      filters: this.fb.array([])
    })


    this.filters.clear()
    for (const [key, value] of Object.entries(this.params)) {
      const data = value.replaceAll('%', '').replace('(', '').replace(')', '').replace('like','') as string

      if(new RegExp(/^in/g).test(data)) {
        const list = data
        .replace('in','')
        .replace('(','')
        .replace(')','').split(',')
        this.form.get(key)?.patchValue(list)
        list.map((value: any) => {
          value = value.replaceAll('%', '').replace('(', '').replace(')', '').replace('like','')
         this.setFilterParamsToForm(key, value)
        })

      } else if(this.itemsFilter.find(elt => elt.property === key)) {
        this.form.get(key)?.patchValue(data)
        this.setFilterParamsToForm(key, data)
      }
    }
    this.addPropertiesForm();
    this.onToggle(FilterAction.OPEN)

  }

  setFilterParamsToForm(key: string, value: string) {
    const itemFilter: Filter = this.itemsFilter.find(elt => elt.property === key)!
    const items = itemFilter.items.map((elt: string) => {
      return {
        id: elt,
        text: elt
      }
    })
    this.filters.push(
      this.fb.group({
        type: [itemFilter.type],
        property: [itemFilter.property],
        text: [itemFilter.text],
        value: [{id: itemFilter.isSearchByText ? `like(%${value}%)` : value, text: value}],
        isOpen: [false, []],
        isSearch: [false, []],
        items: [items, []]
      })
    )
  }

  onChange(index: number) {
    const filter = this.filters.at(index).value;

    if (filter.type == TypeFilter.PROP && filter.value) {
      const itemFilter: Filter = this.itemsFilter.find(elt => elt.text === filter.value)!
      this.filters.removeAt(index)
      const items = itemFilter.items.map((elt: string) => {
        return {
          id: elt,
          text: elt
        }
      })
      this.filters.push(
        this.fb.group({
          type: [itemFilter.type],
          property: [itemFilter.property],
          text: [itemFilter.text],
          value: [''],
          isOpen: [itemFilter.isOpen, []],
          isSearch: [itemFilter.isSearch, []],
          items: [items, []]
        })
      )
      this.onSearch('', index)

    } else if(filter.type == TypeFilter.FILTER && filter.value) {
      this.filters.controls.map((ctlr, i) => {
        ctlr.value.isOpen = false
        ctlr.value.isSearch = false
        if (index == i) {
          ctlr.value.value.text = ctlr.value.value.text.replace(this.userPrint, '')
        }
      })
      this.addPropertiesForm()
      this.onToggle(FilterAction.OPEN)
    }
  }

  onSearch(search: string, index: number) {
    const filter = this.filters.at(index).value as Filter;

     if(filter.type == TypeFilter.FILTER && filter.isSearch) {
      const itemFilter: Filter = this.itemsFilter.find(elt => elt.text === filter.text)!
      let toSearch = `like(%${search}%)`

      itemFilter.observable$!({
        [filter.property]: toSearch,
      })
      .pipe(first())
      .subscribe((items: ValueFilter[]) => {
        if (itemFilter.isSearchByText) {
          items = items.map(value => ({id: `like(%${value.text}%)`, text: value.text}))
          this.filters.at(index).get('items')?.patchValue([
            {id: `like(%${search}%)`, text: `${this.userPrint} ${search}`},
            ...items
          ])
        } else {
          this.filters.at(index).get('items')?.patchValue(items)
        }
        this.filters.at(index).get('value')?.patchValue(search)
        this.form.updateValueAndValidity()
      })
    }
  }

  addPropertiesForm() {
    const filter =  this.itemsFilter.find(elt => elt.type === TypeFilter.PROP)!;
    this.filters.push(
      this.fb.group({
        type: [TypeFilter.PROP],
        property: [''],
        text: [''],
        value: [''],
        items: [filter.items, []],
        isSearch: [false, []],
        isOpen: [filter.isOpen, []]
      })
    )
  }

  clearFilter(index: number){
    const filter = this.filters.at(index).value
    this.filters.removeAt(index)
    if(filter.type == TypeFilter.PROP || this.filters.length === index) {
      this.addPropertiesForm()
    }
  }

  onSubmit() {
    this.onToggle(FilterAction.CLOSE)

    let resultFilter = this.filters.controls.map(((elt) => {
      const filter = elt.value
      return !filter.isOpen && filter.value ? [filter.property, filter.value.id]  : null
    })).filter(elt => elt)

    let filterRequest = resultFilter.reduce((prev: any, current: any, index: number)  =>  {
      const key = current[0]
      const value = current[1]
      prev = index == 0 ? {[prev[0]]: prev[1]} : prev

      let json: Object = {}

      if(prev[key] && index > 0) {
        let previousValue = prev[key].replace('in','')
        .replace('(','')
        .replace(')','')
        json = {[key]: `in(${previousValue},${value})`}
      } else  {
        json = {[key]: value}
      }
      return {...prev, ...json}
    }, resultFilter[0])

    this.getParams.emit(filterRequest!);
  }

  onToggle(action: FilterAction) {
    switch (action) {
      case FilterAction.CLOSE:
        this.filters.at(this.filters.length-1).get('isOpen')?.patchValue(false)
        break;
      case FilterAction.OPEN:
        this.filters.at(this.filters.length-1).get('isOpen')?.patchValue(true)
        break;
      default:
        break;
    }
  }

  onClearAll() {
    this.filters.clear()
    this.addPropertiesForm()
    this.onToggle(FilterAction.OPEN)
  }

  get filters () {
    return this.form.get('filters') as FormArray
  }

}
