import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {range} from 'lodash';


@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styles: []
})
export class PaginationComponent implements OnInit {
  @Input() meta: any;
  @Input() firstPage!: number;
  @Input() lastPage!: number;
  @Input() currentPage!: number;

  @Output() selectedPage = new EventEmitter<number>();

  public numberPerSection = 7;

  /**
   * Pagination constructor
   */
  constructor() {
    //
  }

  /**
   * on init life cycle
   */
  ngOnInit() {
    //
  }

  /**
   * View init life cycle
   */
  ngAfterViewInit() {
    //
  }

  /**
   * Life cycle change
   */
  ngOnChanges() {
    //
  }

  /**
   * Pages pagination component
   * @returns number[]
   */
  pages() {
    /* if (this.firstPage === 0) {
       return range(0, this.lastPage + 1);
     }*/
    return range((this.section() - 1) * this.numberPerSection + 1, this.getLastPage() + 1);
  }

  /**
   * Switchs page
   * @param page [page]
   * @returns page
   */
  switchPage(page: any) {
    this.currentPage = page;
    if (this.currentPage < 0 || this.currentPage > this.lastPage) {
      this.currentPage = page;
      return;
    }

    if (this.firstPage === 0 ) {
      this.currentPage = page - 1;
      this.selectedPage.emit(page - 1);
      return;
    }
    this.selectedPage.emit(page);
  }

  /**
   * Sections pagination component
   * @returns number
   */
  section() {
    if (this.firstPage === 0) {
      return 1;
    }
    return Math.ceil(this.currentPage / this.numberPerSection);
  }

  /**
   * Sections pagination component
   * @returns number
   */
  sections() {
    return Math.ceil(this.lastPage / this.numberPerSection);
  }

  /**
   * Gets last page
   * @returns number
   */
  getLastPage() {
    let lastPage = (this.section() - 1) * this.numberPerSection + this.numberPerSection;

    if (this.section() === this.sections()) {
      lastPage = this.lastPage;
    }

    return lastPage;
  }

  /**
   * Go back section
   */
  goBackAsSection() {
    this.switchPage(this.firstPageOfSection(this.section() - 1));
  }

  /**
   * Go forward section
   */
  goForwardAsSection() {
    this.switchPage(this.firstPageOfSection(this.section() + 1));
  }

  /**
   * Firsts page of section
   * @param section [section]
   * @returns number
   */
  firstPageOfSection(section: number) {
    return (section - 1) * this.numberPerSection + 1;
  }
}
